import Vue from "vue";
import VueRouter from "vue-router";
import physical from "../views/auth/physical.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/physical",
  //   name: "physicalRegister",
  //   component: physical,
  //   meta: { guest: true },
  // },
  // {
  //   path: "/virtual",
  //   name: "virtualRegister",
  //   component: () => import("../views/auth/virtual.vue"),
  //   meta: { guest: true },
  // },
  // {
  //   path: "/success",
  //   name: "formSuccess",
  //   component: () => import("../views/auth/success.vue"),
  //   meta: { guest: true },
  // },
  // {
  //   path: "/failed",
  //   name: "formFailed",
  //   component: () => import("../views/auth/failed.vue"),
  //   meta: { guest: true },
  // },
  {
    path: "/login/:username/:hash",
    name: "login",
    component: () => import("../views/auth/login.vue"),
    meta: { guest: true },
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/home.vue"),
    meta: { guest: true },
  },
  {
    path: "/lobby",
    name: "lobby",
    component: () => import("../views/lobby.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/speaker/:slug",
    name: "speakersList",
    component: () => import("../views/speakers.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/livestream/:slug",
    name: "livestream",
    component: () => import("../views/livestream.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/programme",
    name: "programme",
    component: () => import("../views/programme.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/logout.vue"),
    meta: { guest: true },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
