<template>
  <div id="app">
    <div id="note" v-if="settings.show_bar">
      {{ settings.bar_message }}
      <a
        :href="settings.button_url"
        class="btn btn-secondary btn-sm ml-2"
        v-if="settings.button_label"
      >
        {{ settings.button_label }}
      </a>
    </div>
    <router-view v-if="$route.meta.requiresAuth && user.id" />
    <router-view v-if="$route.meta.guest" />
  </div>
</template>
<script>
import util from "@/assets/js/util";
import Api from "@/assets/js/Api";
import Csrf from "@/assets/js/Csrf";
import { createLogger, mapState } from "vuex";
export default {
  name: "app",
  computed: mapState(["user", "idleVue", "settings"]),
  watch: {
    $route() {
      util.auth_middleware(this);
    },
    idleVue: {
      // This will let Vue know to look inside the array
      deep: true,
      // We have to move our method to a handler field
      handler() {
        // return;
        if (this.$route.name == "livestream") return;
        if (this.user?.id) {
          if (this.idleVue.isIdle) {
            Csrf.getCookie().then((res) => {
              Api.post("log_out").then((res) => location.reload());
            });
          }
        }
      },
    },
  },
  methods: {
    watchSocketChanges() {
      Echo.channel("trigger-change").listen("TriggerChange", (e) => {
        this.getChangeType(e.message);
      });
    },
    getSettingsFromApi() {
      Api.get("/get_settings").then((res) => {
        this.setSettings(res.data.data);
      });
    },
    setSettings(settings) {
      if (settings) localStorage.setItem("settings", JSON.stringify(settings));
      if (localStorage.getItem("settings")) {
        let settings = JSON.parse(localStorage.getItem("settings"));
        this.$store.commit("settings", settings);
        return;
      }
      this.$store.commit("settings", {});
    },
    getChangeType(message) {
      if (message.type == "settings") this.setSettings(message.data);
      if (message.type == "device-limit") this.triggerLogout(message.data);
      if (message.type == "refresh") location.reload();
    },
    triggerLogout(id) {
      if (id.id == this.user.id) {
        location.reload();
        // location.href = "/device-limit-exceed";
      }
    },
  },
  mounted() {
    util.auth_middleware(this);
    this.watchSocketChanges();
    this.getSettingsFromApi();
    Echo.join(`chat`);
    // setTimeout(() => {
    //   if (this.$route.path == "/") {
    //     this.$router.push("/physical");
    //   }
    // }, 1000);

  
  },
};
</script>
