import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    settings: {},
    user: {
      id: 1,
    },
    // base_url:'https://satec2022.klobbi.sg',
    // base_url:'http://localhost:8080',
    speakers: {
      guest_of_honours: {
        id: 1,
        thumbnail: "buttons/1.png",
        image: "goh/goh.png",
        linkenable: false,
      },
      conference_speakers: {
        id: 2,
        thumbnail: "buttons/2.png",
        linkenable: true,
        bg: "conference/bg.png",
        slug: "conference_speakers",
        tracks: {
          DISTINGUISHED_SPEAKERS: {
            id: 1,
            title: "DISTINGUISHED SPEAKERS",
            list: [
              {
                id: 1,
                thumbnail: "distinguishedspeakers/1.png",
                name: "Lieutenant General (Armament) Thierry Carlier",
                designation:
                  "Director of International Development, Directorate General for Armament (DGA)",
                bio: "distinguishedspeakers/popups/1.png",
                mbbio: "distinguishedspeakers/popups/1.png",
              },
              {
                id: 2,
                thumbnail: "distinguishedspeakers/2.png",
                name: "Ms Leanne G. Caret",
                designation:
                  "President & CEO, Boeing Defense, Space & Security, USA",
                bio: "distinguishedspeakers/popups/2.png",
                mbbio: "distinguishedspeakers/popups/2.png",
              },
              {
                id: 3,
                thumbnail: "distinguishedspeakers/3.png",
                name: "Mr Howard Nye",
                designation: "President, Royal Aeronautical Society (RAeS)",
                bio: "distinguishedspeakers/popups/3.png",
                mbbio: "distinguishedspeakers/popups/3.png",
              },
              {
                id: 4,
                thumbnail: "distinguishedspeakers/4.png",
                name: "Mr Bruno Sainjon",
                designation:
                  "Chairman and CEO, Office National d'Etudes et de Recherches Aérospatiales (ONERA)",
                designation2:
                  "Chair, European Space Research Establishments (ESRE)",
                designation3:
                  "Vice-Chair, International Forum for Aviation Research (IFAR)",
                bio: "distinguishedspeakers/popups/4.png",
                mbbio: "distinguishedspeakers/popups/4.png",
              },
            ],
          },
          PLENARY_SPEAKERS: {
            id: 2,
            title: "PLENARY SPEAKERS",
            list: [
              {
                id: 1,
                thumbnail: "plenaryspeakers/1.png",
                name: "Ms Lirio Liu",
                designation:
                  "Executive Director for the Officer of International Affairs, Federal Aviation Administration (FAA)",
                bio: "plenaryspeakers/popups/1.png",
                mbbio: "plenaryspeakers/popups/1.png",
              },
              {
                id: 2,
                thumbnail: "plenaryspeakers/2.png",
                name: "Dr Francis R. Preli, Jr.",
                designation:
                  "Vice President, Propulsion & Materials Technologies, Pratt & Whitney",
                bio: "plenaryspeakers/popups/2.png",
                mbbio: "plenaryspeakers/popups/2.png",
              },
              {
                id: 3,
                thumbnail: "plenaryspeakers/3.png",
                name: "Military Expert 7 (ME7) Tay Gek Peng",
                designation:
                  "Head RSAF Agile, Innovation and Digital, Republic of Singapore Air Force",
                bio: "plenaryspeakers/popups/3.png",
                mbbio: "plenaryspeakers/popups/3.png",
              },
              {
                id: 4,
                thumbnail: "plenaryspeakers/4.png",
                name: "Ms Sheila Remes",
                designation:
                  "Vice President, Environmental Sustainability, The Boeing Company",
                bio: "plenaryspeakers/popups/4.png",
                mbbio: "plenaryspeakers/popups/4.png",
              },
            ],
          },
          CLOSING_REMARKS: {
            id: 3,
            title: "CLOSING REMARKS",
            list: [
              {
                id: 1,
                thumbnail: "closingremark/1.png",
                name: "Dr Victoria Coleman",
                designation: "Chief Scientist, United States Air Force (USAF)",
                bio: "closingremark/popups/1.png",
                mbbio: "closingremark/popups/1.png",
              },
            ],
          },
        },
      },
      breakout_room_speakers: {
        id: 3,
        thumbnail: "buttons/3.png",
        linkenable: true,
        slug: "breakout_room_speakers",
        tracks: {
          track1: {
            id: 1,
            title: "TRACK 1: MAINTENANCE OF THE FUTURE",
            bg: "breakout/track1/background.png",
            list: [
              {
                id: 1,
                thumbnail: "track1/1.png",
                name: "Ms Sarit Assaraf",
                designation: "Israeli Aerospace Industries",
                bio: "track1/popups/1.png",
                mbbio: "track1/popups/1.png",
              },
              {
                id: 2,
                thumbnail: "track1/2.png",
                name: "Ms Koh Liang Ying",
                designation: "SIA Engineering Company",
                bio: "track1/popups/2.png",
                mbbio: "track1/popups/2.png",
              },
              {
                id: 3,
                thumbnail: "track1/3.png",
                name: "Mr Itzhak Pichadze",
                designation: "L.L. Studio Software LTD",
                bio: "track1/popups/3.png",
                mbbio: "track1/popups/3.png",
              },
              {
                id: 4,
                thumbnail: "track1/4.png",
                name: "Mr Gilad Tzori",
                designation: "L.L. Studio Software LTD",
                bio: "track1/popups/4.png",
                mbbio: "track1/popups/4.png",
              },
              {
                id: 5,
                thumbnail: "track1/5.png",
                name: "Mr Sito Kenwyn",
                designation: "Defence Science and Technology Agency (DSTA)",
                bio: "track1/popups/5.png",
                mbbio: "track1/popups/5.png",
              },
              {
                id: 6,
                thumbnail: "track1/6.png",
                name: "Dr Mark Rice",
                designation:
                  "Agency for Science, Technology and Research (A*STAR)",
                bio: "track1/popups/6.png",
                mbbio: "track1/popups/6.png",
              },
              {
                id: 7,
                thumbnail: "track1/10.png",
                name: "ME4 Kelvin Bi",
                designation: "Republic of Singapore Air Force (RSAF)",
                bio: "track1/popups/7.png",
                mbbio: "track1/popups/7.png",
              },
              {
                id: 8,
                thumbnail: "track1/9.png",
                name: "Dr Koh Pak Keng",
                designation: "ECK Pte Ltd",
                bio: "track1/popups/8.png",
                mbbio: "track1/popups/8.png",
              },
              {
                id: 9,
                thumbnail: "track1/8.png",
                name: "Dr Kelvin Loke Zhi Cheng",
                designation: "ECK Pte Ltd",
                bio: "track1/popups/9.png",
                mbbio: "track1/popups/9.png",
              },
              {
                id: 10,
                thumbnail: "track1/7.png",
                name: "Dr Saurab Verma",
                designation:
                  "Agency for Science, Technology and Research (A*STAR)",
                bio: "track1/popups/10.png",
                mbbio: "track1/popups/10.png",
              },
              {
                id: 11,
                thumbnail: "track1/11.png",
                name: "Dr Zheng Guo Ying",
                designation: "ST Engineering",
                bio: "track1/popups/11.png",
                mbbio: "track1/popups/11.png",
              },
              {
                id: 12,
                thumbnail: "track1/12.png",
                name: "Mr Zhang Yongjie",
                designation: "ST Engineering",
                bio: "track1/popups/12.png",
                mbbio: "track1/popups/12.png",
              },
              {
                id: 13,
                thumbnail: "track1/13.png",
                name: "Dr Zhang Zheng",
                designation:
                  "Agency for Science, Technology and Research (A*STAR)",
                bio: "track1/popups/13.png",
                mbbio: "track1/popups/13.png",
              },
              {
                id: 14,
                thumbnail: "track1/14.png",
                name: "ME5 Adrian Chua",
                designation: "Republic of Singapore Air Force",
                bio: "track1/popups/14.png",
                mbbio: "track1/popups/14.png",
              },
              {
                id: 15,
                thumbnail: "track1/15.png",
                name: "ME4 Gabriel Wee",
                designation: "Republic of Singapore Air Force (RSAF)",
                bio: "track1/popups/15.png",
                mbbio: "track1/popups/15.png",
              },
            ],
          },
          track2: {
            id: 2,
            title: "TRACK 2: GREEN TECHNOLOGIES",
            bg: "breakout/track2/background.png",
            list: [
              {
                id: 1,
                thumbnail: "track2/1.png",
                name: "Mr Shannon Parker",
                designation: "Boeing",
                bio: "track2/popups/1.png",
                mbbio: "track2/popups/1.png",
              },
              {
                id: 2,
                thumbnail: "track2/2.png",
                name: "Mr Vincent Caulet",
                designation:
                  "Centre Technique des Industries Mécaniques (CETIM)",
                bio: "track2/popups/2.png",
                mbbio: "track2/popups/2.png",
              },
              {
                id: 3,
                thumbnail: "track2/3.png",
                name: "Dr Jeremy Viale",
                designation:
                  "Centre Technique des Industries Mécaniques (CETIM) ",
                bio: "track2/popups/3.png",
                mbbio: "track2/popups/3.png",
              },
              {
                id: 4,
                thumbnail: "track2/4.png",
                name: "Mr Soh Tian Boon",
                designation: "Defence Science and Technology Agency (DSTA) ",
                bio: "track2/popups/4.png",
                mbbio: "track2/popups/4.png",
              },
              {
                id: 5,
                thumbnail: "track2/5.png",
                name: "Mr Alireza Yaghoubi",
                designation: "AirGo Design Pte Ltd ",
                bio: "track2/popups/5.png",
                mbbio: "track2/popups/5.png",
              },
              {
                id: 6,
                thumbnail: "track2/6.png",
                name: "Mr See Kah Meng",
                designation: "Safran Helicopter Engines ",
                bio: "track2/popups/6.png",
                mbbio: "track2/popups/6.png",
              },
              {
                id: 7,
                thumbnail: "track2/7.png",
                name: "Mr Sean Bradshaw",
                designation: "Pratt & Whitney",
                bio: "track2/popups/7.png",
                mbbio: "track2/popups/7.png",
              },
              {
                id: 8,
                thumbnail: "track2/8.png",
                name: "Dr Chen Luwei",
                designation:
                  "Agency for Science, Technology and Research (A*STAR)",
                bio: "track2/popups/8.png",
                mbbio: "track2/popups/8.png",
              },
              {
                id: 9,
                thumbnail: "track2/9.png",
                name: "Mr Lars Seumenicht",
                designation: "Rolls-Royce Deutschland",
                bio: "track2/popups/9.png",
                mbbio: "track2/popups/9.png",
              },
              {
                id: 10,
                thumbnail: "track2/10.png",
                name: "Prof Kirill Sypalo",
                designation:
                  "Central AeroHydrodynamic Institute named after Professor N.E. Zhukovsky",
                bio: "track2/popups/10.png",
                mbbio: "track2/popups/10.png",
              },
            ],
          },
          track3: {
            id: 3,
            title: "4IR IN AEROSPACE",
            bg: "breakout/track3/background.png",
            list: [
              {
                id: 1,
                thumbnail: "track3/1.png",
                name: "Mr Darren Macer",
                designation: "Boeing",
                bio: "track3/popups/1.png",
                mbbio: "track3/popups/1.png",
              },
              {
                id: 2,
                thumbnail: "track3/2.png",
                name: "Mr Julien Valette",
                designation: "CW Aero Services Pte Ltd",
                bio: "track3/popups/2.png",
                mbbio: "track3/popups/2.png",
              },
              {
                id: 3,
                thumbnail: "track3/3.png",
                name: "Mr Alexander Kasinec",
                designation: "Evitado Technologies",
                bio: "track3/popups/3.png",
                mbbio: "track3/popups/3.png",
              },
              {
                id: 4,
                thumbnail: "track3/4.png",
                name: "ME5 Adrian Tan",
                designation: "Republic of Singapore Air Force (RSAF)",
                bio: "track3/popups/4.png",
                mbbio: "track3/popups/4.png",
              },
              {
                id: 5,
                thumbnail: "track3/5.png",
                name: "ME4 Tan Di Teng Desmond",
                designation: "Republic of Singapore Air Force (RSAF)",
                bio: "track3/popups/5.png",
                mbbio: "track3/popups/5.png",
              },
              {
                id: 6,
                thumbnail: "track3/6.png",
                name: "Mr Goh Yu Heng",
                designation: "ST Engineering",
                bio: "track3/popups/6.png",
                mbbio: "track3/popups/6.png",
              },
              {
                id: 7,
                thumbnail: "track3/7.png",
                name: "Mr Christoph Selig",
                designation: "Unisphere GmbH",
                bio: "track3/popups/7.png",
                mbbio: "track3/popups/7.png",
              },
              {
                id: 8,
                thumbnail: "track3/8.png",
                name: "Mr Chok Ng Yong",
                designation: "ST Aerospace",
                bio: "track3/popups/8.png",
                mbbio: "track3/popups/8.png",
              },
              {
                id: 9,
                thumbnail: "track3/9.png",
                name: "Mr Yong Zhaoyuan Jonathan",
                designation: "Defence Science Organisation (DSO)",
                bio: "track3/popups/9.png",
                mbbio: "track3/popups/9.png",
              },
              {
                id: 10,
                thumbnail: "track3/10.png",
                name: "Ms Lee Wei Xin",
                designation: "Defence Science and Technology Agency (DSTA)",
                bio: "track3/popups/10.png",
                mbbio: "track3/popups/10.png",
              },
              {
                id: 11,
                thumbnail: "track3/11.png",
                name: "Dr Sutthiphong Srigrarom",
                designation: "National University of Singapore",
                bio: "track3/popups/11.png",
                mbbio: "track3/popups/11.png",
              },
              {
                id: 12,
                thumbnail: "track3/12.png",
                name: "Prof Chiu Wing Kong",
                designation: "Monash University",
                bio: "track3/popups/12.png",
                mbbio: "track3/popups/12.png",
              },
              {
                id: 13,
                thumbnail: "track3/13.png",
                name: "ME4 Lee Jiyou",
                designation: "Republic of Singapore Air Force (RSAF)",
                bio: "track3/popups/13.png",
                mbbio: "track3/popups/13.png",
              },
            ],
          },
          track4: {
            id: 4,
            title: "ADVANCED AEROSPACE TOPICS",
            bg: "breakout/track4/background.png",
            list: [
              {
                id: 1,
                thumbnail: "track4/1.png",
                name: "Mr Ziv Freund",
                designation: "Elbit Systems",
                bio: "track4/popups/1.png",
                mbbio: "track4/popups/1.png",
              },
              {
                id: 2,
                thumbnail: "track4/2.png",
                name: "Dr Jeremy Yune",
                designation:
                  "Agency for Science, Technology and Research (A*STAR)",
                bio: "track4/popups/2.png",
                mbbio: "track4/popups/2.png",
              },
              {
                id: 3,
                thumbnail: "track4/3.png",
                name: "Dr Ng Bing Feng",
                designation: "Nanyang Technological University",
                bio: "track4/popups/3.png",
                mbbio: "track4/popups/3.png",
              },
              {
                id: 4,
                thumbnail: "track4/4.png",
                name: "Ms Thivyaa Ramesh",
                designation:
                  "Agency for Science, Technology and Research (A*STAR)",
                bio: "track4/popups/4.png",
                mbbio: "track4/popups/4.png",
              },
              {
                id: 5,
                thumbnail: "track4/5.png",
                name: "Dr Niroj Maharjan",
                designation:
                  "Agency for Science, Technology and Research (A*STAR)",
                bio: "track4/popups/5.png",
                mbbio: "track4/popups/5.png",
              },
              {
                id: 6,
                thumbnail: "track4/6.png",
                name: "Dr Armen Baronian",
                designation: "Eaton",
                bio: "track4/popups/6.png",
                mbbio: "track4/popups/6.png",
              },
              {
                id: 7,
                thumbnail: "track4/7.png",
                name: "Dr Paw Yew Chai",
                designation: "Singapore Institute of Technology",
                bio: "track4/popups/7.png",
                mbbio: "track4/popups/7.png",
              },
              {
                id: 8,
                thumbnail: "track4/8.png",
                name: "Mr Adrian Lee",
                designation: "Textile Solutions",
                bio: "track4/popups/8.png",
                mbbio: "track4/popups/8.png",
              },
              {
                id: 9,
                thumbnail: "track4/9.png",
                name: "ME5 Ong Wen Xiang",
                designation: "Republic of Singapore Air Force (RSAF)",
                bio: "track4/popups/9.png",
                mbbio: "track4/popups/9.png",
              },
              {
                id: 10,
                thumbnail: "track4/10.png",
                name: "Mr Steve Sheehy",
                designation: "Lockheed Martin",
                bio: "track4/popups/10.png",
                mbbio: "track4/popups/10.png",
              },
              {
                id: 11,
                thumbnail: "track4/11.png",
                name: "ME5 Colin Yeo ",
                designation: "Republic of Singapore Air Force (RSAF)",
                bio: "track4/popups/11.png",
                mbbio: "track4/popups/11.png",
              },
              {
                id: 12,
                thumbnail: "track4/12.png",
                name: "Mr Jason Baek ",
                designation: "Honeywell Aerospace",
                bio: "track4/popups/12.png",
                mbbio: "track4/popups/12.png",
              },
            ],
          },
        },
      },
    },
    livestreams: {
      "4ir": {
        id: 1,
        bg: "4ir/bg.png",
        branding: "4ir/text.png",
        slug: "4ir",
        main_stream: "https://player.vimeo.com/video/678490231?h=ce2f6d3f1c",
        chat: "https://vimeo.com/event/1700625/chat/",
        qna: "https://app.sli.do/event/jC6jPxDdpL8BR8wyAg3aHM",
        back: "4ir/back.png",
        view_more: "4ir/res.png",
        resources: [
          {
            thumbnail: "4ir/thumbnail/video.png",
            link: "/video/br3/video.mp4",
            pdf: false,
          },
          {
            thumbnail: "4ir/thumbnail/1.png",
            link: "/pdf/br3/1.pdf",
            pdf: true,
          },
        ],
      },
      advanced_aerospace_technology: {
        id: 2,
        bg: "advance/bg.png",
        branding: "advance/text.png",
        slug: "advanced_aerospace_technology",
        main_stream: "https://player.vimeo.com/video/678507441?h=9aca8f8307",
        chat: "https://vimeo.com/event/1700629/chat/",
        qna: "https://app.sli.do/event/aZhKfT2P4d14EEcj2vhxS9",
        back: "advance/back.png",
        view_more: "advance/res.png",
        resources: [
          {
            thumbnail: "advance/thumbnail/pdf.png",
            link: "/pdf/br4/1.pdf",
            pdf: true,
          },
          {
            thumbnail: "advance/thumbnail/2.png",
            link: "/pdf/br4/2.pdf",
            pdf: true,
          },
          {
            thumbnail: "advance/thumbnail/video.png",
            link: "/video/br4/video.mp4",
            pdf: false,
          },
        ],
      },
      green_technology: {
        id: 3,
        bg: "green/bg.png",
        branding: "green/text.png",
        slug: "green_technology",
        main_stream: "https://player.vimeo.com/video/678513639?h=6707b70fc3",
        chat: "https://vimeo.com/event/1700654/chat/",
        qna: "https://app.sli.do/event/4iHk7ag3KNW3edaTTwH4hG",
        back: "green/back.png",
        view_more: "green/res.png",
        resources: [
          {
            thumbnail: "green/thumbnail/1.png",
            link: "/pdf/br2/1.pdf",
            pdf: true,
          },
          {
            thumbnail: "green/thumbnail/2.png",
            link: "/pdf/br2/2.pdf",
            pdf: true,
          },
        ],
      },
      maintenance_of_future: {
        id: 4,
        bg: "maintenance/bg.png",
        branding: "maintenance/text.png",
        slug: "maintenance_of_future",
        main_stream: "https://player.vimeo.com/video/678527056?h=85d52cdd25",
        chat: "https://vimeo.com/event/1700657/chat/",
        qna: "https://app.sli.do/event/2YVHWpPeFSAENP5kWymfxQ",
        back: "maintenance/back.png",
        view_more: "maintenance/add.png",
        resources: [
          {
            thumbnail: "maintenance/thumbnail/1.png",
            link: "/pdf/br1/1.pdf",
            pdf: true,
          },
          {
            thumbnail: "maintenance/thumbnail/2.png",
            link: "/pdf/br1/2.pdf",
            pdf: true,
          },
        ],
      },
      conference: {
        id: 4,
        bg: "conference/bg.png",
        branding: "conference/text.png",
        slug: "conference",
        main_stream: "https://player.vimeo.com/video/678535857?h=8870a76ec1",
        chat: "https://vimeo.com/event/1700630/chat/",
        qna: "https://app.sli.do/event/3YAJEpaHbRrKEgz1bWZn1C",
        back: "conference/back.png",
      },
    },
    abouts: {
      satec_2021: {
        id: 1,
        title: "satec 2021",
        thumbnail: "about/1.png",
        dimg: "img/1.png",
        mimg: "img/m1.png",
      },
      preface: {
        id: 2,
        title: "preface",
        thumbnail: "about/2.png",
        dimg: "img/2.png",
        mimg: "img/m2.png",
      },
      about_organisers: {
        id: 3,
        title: "about organisers",
        thumbnail: "about/3.png",
        dimg: "img/3.png",
        mimg: "img/m3.png",
      },
      organising_committe_chairman: {
        id: 4,
        title: "organising committe chairman",
        thumbnail: "about/4.png",
        dimg: "img/4.png",
        mimg: "img/m4.png",
      },
    },
  },
  mutations: {
    user(state, data) {
      state.user = data;
    },
    settings(state, data) {
      state.settings = data;
    },
  },
  actions: {},
  modules: {},
});
