<template>
  <div class="physical">
    <img
      src="@/assets/img/physical/banner.png"
      class="img-fluid"
      alt="banner image"
    />
    <div class="reg-tab">
      <div class="row text-center no-gutters">
        <div class="col-6 link">
          <router-link to="/physical" class="py-4">
            Physical
            <p class="mb-0">$500</p>
          </router-link>
        </div>
        <div class="col-6 link">
          <router-link to="/virtual" class="py-4">
            Virtual
            <p class="mb-0">$250</p>
          </router-link>
        </div>
      </div>
    </div>
    <div class="form py-5">
      <div class="container-lg">
        <h3>Physical</h3>
        <h4 class="b-600">Session Attendance Selection</h4>
        <p>
          Please note that the slots for live attendance are limited and will be
          allocated to participants on a first-come-first-served basis.
        </p>
        <div class="mb-4">
          <div class="form-check d-flex align-items-center mb-2">
            <input
              class="form-check-input mt-0"
              type="checkbox"
              disabled
              v-model="session_time_am"
              name="session_time"
              id="am"
            />
            <label class="form-check-label ml-2" for="am"> AM </label>
          </div>
          <div class="form-check d-flex align-items-center">
            <input
              class="form-check-input mt-0"
              disabled
              type="checkbox"
              v-model="session_time_pm"
              name="session_time"
              id="pm"
            />
            <label class="form-check-label ml-2" for="pm"> PM<span style="color:red"  >*</span> </label>
          </div>
          <span
            class="text-danger"
            v-for="message of validation_message.session_time"
            >{{ message }}<br
          /></span>
        </div>
        <div class="row">
          <div class="col-6 col-sm-6 col-md-3 col-lg-3 mb-3">
            <div class="pl-0 form-check d-flex flex-column align-items-center">
              <input
                class="form-check-input ml-0"
                type="radio"
                name="session_radio"
                id="maintenance"
                v-model="participant.session"
                value="maintenance_of_the_future"
                checked
              />
              <label class="form-check-label mt-4" for="maintenance">
                <img
                  src="@/assets/img/physical/1.png"
                  class="img-fluid"
                  alt="maintenance"
                />
              </label>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-3 col-lg-3 mb-3">
            <div class="pl-0 form-check d-flex flex-column align-items-center">
              <input
                class="form-check-input ml-0"
                type="radio"
                name="session_radio"
                id="greentechnology"
                v-model="participant.session"
                value="green_technology"
                checked
              />
              <label class="form-check-label mt-4" for="greentechnology">
                <img
                  src="@/assets/img/physical/2.png"
                  class="img-fluid"
                  alt="maintenance"
                />
              </label>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-3 col-lg-3 mb-3">
            <div class="pl-0 form-check d-flex flex-column align-items-center">
              <input
                class="form-check-input ml-0"
                type="radio"
                name="session_radio"
                v-model="participant.session"
                id="4ir"
                value="4ir_in_aerospace"
              />
              <label class="form-check-label mt-4" for="4ir">
                <img
                  src="@/assets/img/physical/3.png"
                  class="img-fluid"
                  alt="maintenance"
                />
              </label>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-3 col-lg-3 mb-3">
            <div class="pl-0 form-check d-flex flex-column align-items-center">
              <input
                class="form-check-input ml-0"
                type="radio"
                name="session_radio"
                v-model="participant.session"
                id="advancedaerospacetech"
                value="advanced_aerospace_technology"
                checked
              />
              <label class="form-check-label mt-4" for="advancedaerospacetech">
                <img
                  src="@/assets/img/physical/4.png"
                  class="img-fluid"
                  alt="maintenance"
                />
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <p class="b-600 mb-0">
              *Changes to your selected PM slot will not be allowed.
            </p>
          </div>
        </div>
        <h4 class="b-600 my-3">Personal Particulars</h4>
        <div class="form-group">
          <label for="fullname" class="b-600">Full Name</label>
          <input
            type="text"
            name="fullname"
            id="fullname"
            class="form-control"
            v-model="participant.name"
          />
          <span class="text-danger" v-for="message of validation_message.name"
            >{{ message }}<br
          /></span>
        </div>
        <div class="form-group">
          <label for="organisation" class="b-600">Organisation</label>
          <input
            type="text"
            name="organisation"
            id="organisation"
            class="form-control"
            v-model="participant.organization"
          />
          <span
            class="text-danger"
            v-for="message of validation_message.organization"
            >{{ message }}<br
          /></span>
        </div>
        <div class="form-group">
          <label for="email" class="b-600 mb-0"
            >Email Address (Please input your business email address)</label
          >
          <p class="small mb-1">
            Do note that all correspondence and login for event will be via the
            email address provided below
          </p>
          <input
            v-model="participant.email"
            type="email"
            name="email"
            id="email"
            class="form-control"
          />
          <span class="text-danger" v-for="message of validation_message.email"
            >{{ message }}<br
          /></span>
        </div>
        <div class="form-group">
          <label for="promocode" class="b-600"
            >Have a promo code? Apply here!</label
          >
          <input
            type="text"
            name="promocode"
            id="promocode"
            v-model="code"
            class="form-control"
          />
          <span class="text-danger" v-if="promo_error">{{ promo_error }}</span>
        </div>
        <div class="mt-4">
          <label class="b-600">Vaccination status</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value="1"
              v-model="participant.vaccination_status"
              id="vaccination"
            />
            <label class="form-check-label" for="vaccination">
              By stating your interest to attend the live conference, kindly
              ensure that you can enter settings where prevailing
              vaccination-differentiated safe management measures (VDS) are
              implemented.
            </label>
            <span
              class="text-danger"
              v-for="message of validation_message.vaccination_status"
              >{{ message }}<br
            /></span>
          </div>
        </div>
        <div class="mt-4">
          <label class="b-600">Privacy Statement</label>
          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="privacy"
              v-model="terms"
            />
            <label class="form-check-label" for="privacy">
              I have read the Privacy Statement*
            </label>
          </div>
          <div class="px-3">
            <p class="mb-1">*link to privacy statement</p>
            <a href="https://bit.ly/2XUgu4I" target="_blank"
              >https://bit.ly/2XUgu4I</a
            >
          </div>
        </div>
        <p class="small my-4">
          By filling up and submitting this form, you are consenting to the use
          of your personal data by the Organising Committee to identify and
          contact you for the purpose of SATEC 2022. Your information may be
          sent to external parties to facilitate clearance into their premises.
        </p>

        <button class="btn btn-primary btn-lg round-btn" @click="store">
          Proceed to Payment
        </button>
      </div>
    </div>
    <footer>
      <img
        src="@/assets/img/physical/footer.png"
        class="img-fluid"
        alt="footer image"
      />
    </footer>
  </div>
</template>

<script>
import Api from "@/assets/js/Api";
import Csrf from "@/assets/js/Csrf";
export default {
  name: "physicalRegister",
  data() {
    return {
      terms: false,
      session_time_am: true,
      session_time_pm: true,
      participant: {
        name: "",
        organization: "",
        reg_type: "PHYSICAL",
        email: "",
        session_time: "am",
        session: "maintenance_of_the_future",
        vaccination_status: null,
      },
      code: "",
      validation_message: {
        name: [],
        organization: [],
        reg_type: "PHYSICAL",
        email: [],
        vaccination_status: [],
      },
      promo_error: "",
    };
  },
  methods: {
    store() {
      if (!this.terms) return;
      if (!this.participant.vaccination_status)
        this.participant.vaccination_status = null;
      this.participant.session_time = null;
      if (this.session_time_am || this.session_time_pm)
        this.participant.session_time = JSON.stringify({
          am: this.session_time_am,
          pm: this.session_time_pm,
        });

      Csrf.getCookie().then((res) => {
        Api.post("store-physical", {
          guest: this.participant,
          code: this.code,
        }).then((res) => {
          if (res.data.validation_error) {
            this.validation_message = res.data.validation_message;
            return;
          }
          if (res.data.promo_error) {
            this.promo_error = res.data.message;
            return;
          }
          this.resetValidation();
          if (res.data.error && !res.data.promo_error) {
            alert(res.data.message);
            return;
          }
          if (res.data.url) location.href = res.data.url;
        });
      });
    },
    resetValidation() {
      this.validation_message = {
        name: [],
        organization: [],
        reg_type: "PHYSICAL",
        email: [],
        vaccination_status: [],
      };
      this.promo_error = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.physical {
  .form {
    h3 {
      font-size: 2.5rem;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $primary;
      font-weight: 300;
    }
    h4 {
      color: $primary;
    }
  }
}
</style>
