import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "bootstrap";
import IdleVue from "idle-vue";
import "./assets/css/scss/app.scss";
import Csrf from "@/assets/js/Csrf";
import Api from "@/assets/js/Api";

Vue.config.productionTip = false;

import Echo from "laravel-echo";
import { createLogger } from "vuex";
window.Pusher = require("pusher-js");

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: 'c37bcaa254a2fb40c681',
//     cluster: 'mt1',
//     // wsHost: 'adm-cemea.visasecuritysummit.com',
//     wsHost: location.hostname,

//     wsPort: 6001,
//     wssPort: 6001,
//     authorizer: (channel, options) => {
//         return {
//             authorize: (socketId, callback) => {
//                 Csrf.getCookie().then(res => {
//                     console.log(res);
//                     axios.post('http://localhost:8000/api/broadcasting/auth', {
//                         socket_id: socketId,
//                         channel_name: channel.name
//                     }, { withCredentials: true })
//                         .then(response => {
//                             callback(false, response.data);
//                         })
//                         .catch(error => {
//                             callback(true, error);
//                         });
//                 })

//             }
//         };
//     },

// });

window.Echo = new Echo({
  broadcaster: "pusher",
  key: "c37bcaa254a2fb40c681",
  cluster: "mt1",
  wsHost: "admin-satec2022.klobbi.sg",
  // wsHost: location.hostname,

  // wsPort: 6001,
  wssPort: 8443,
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        Csrf.getCookie().then((res) => {
          Api.post("/broadcasting/auth", {
            socket_id: socketId,
            channel_name: channel.name,
          })
            .then((response) => {
              // console.log(response);
              callback(false, response.data);
            })
            .catch((error) => {
              // console.log(error);

              callback(true, error);
            });
        });
      },
    };
  },
});
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 900000, // 15 minutes
  startAtIdle: false,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
