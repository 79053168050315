import axios from "axios";

let Api = axios.create({
  baseURL: "https://admin-satec2022.klobbi.sg/api",
  //   baseURL: "http://localhost:8000/api",
});

Api.defaults.withCredentials = true;

export default Api;
