import Api from "./Api";
import Csrf from "./Csrf";
const util = {
  async auth_middleware(app, event = null, type = "visit") {
    return true;
    let path = app.$route.path;
    if (path == "/" || app.$route.name == "login")
      return;
    // app.$store.commit("user", { id: 1 });
    // return;
    if (event) {
      path = event;
    }

    // required auth route
    await Csrf.getCookie()
      .then((res) => {
        Api.post(`is_logged_in?event=${path}&type=${type}`)
          .then((res) => {
            app.$store.commit("user", res.data.guest);
            if (app.$route.meta.guest) app.$router.push("/lobby");
          })
          .catch((e) => {
            if (app.$route.meta.requiresAuth) app.$router.push("/logout");
          });
      })
      .catch((res) => {
        if (app.$route.meta.requiresAuth) app.$router.push("/logout");
      });
  },

  getMessageCount() {
    let count = localStorage.getItem("message_count");
    if (!count) this.resetMessageCount();
    return localStorage.getItem("message_count");
  },
  resetMessageCount() {
    localStorage.setItem("message_count", 0);
  },
  incrementMessageCount() {
    let count = this.getMessageCount();
    count++;
    localStorage.setItem("message_count", count);
  },
};
export default util;
